import { getAccessToken, unsetTokens } from 'common/helpers/cookies';
import { Cookies } from 'quasar';
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function ({ store, ssrContext }) {
  const router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE,
  });

  router.beforeEach(async (to, from, next) => {
    if (!store.$isServiceHost && !store.$isPublicHost && [
      ...Object.values(ENUMS.ROUTES.AUTH),
      ...Object.values(ENUMS.ROUTES.DASHBOARD),
    ].includes(to.name)) {
      return next({
        name: ENUMS.ROUTES.NOT_FOUND,
        params: { ...to.params },
      });
    }

    const { guestOnly, subscription } = to.meta;
    const currentRoute = to.matched.find((record) => record.name === to.name);
    const checkAuth = (route) => {
      if (Object.prototype.hasOwnProperty.call(route.meta, 'auth')) {
        return route.meta.auth;
      }
      if (route.parent) {
        return checkAuth(route.parent);
      }
      return false;
    };
    const isPrivateRoute = checkAuth(currentRoute);
    const { clientToken } = to.query;

    if (ssrContext && clientToken) {
      const options = {
        path: '/',
        expires: 7,
        domain: process.env.COOKIE_HOST,
      };
      const cookies = Cookies.parseSSR(ssrContext);
      const token = getAccessToken(ssrContext);
      unsetTokens(ssrContext, process.env.COOKIE_HOST);
      cookies.set('accessToken', clientToken, options);
      if (!cookies.has('sourceAccessToken')) {
        cookies.set('sourceAccessToken', token, options);
      }
      cookies.set('isMasquerade', true, options);
    }

    const token = getAccessToken(ssrContext);

    if (token && !store.getters['app/isAuth']) {
      try {
        await store.dispatch('app/identity');
      } catch {
        unsetTokens(ssrContext, process.env.COOKIE_HOST);
      }
    }

    // Processing pages accessible only to guests, line Sign in, Sign Up, etc.
    if (guestOnly && store.getters['app/isAuth']) {
      return next({ name: ENUMS.ROUTES.INDEX });
    }

    if (subscription && !store.getters['app/isActiveAccount']) {
      return next({ name: ENUMS.ROUTES.INDEX });
    }

    if (isPrivateRoute && !store.getters['app/isAuth']) {
      store.commit('app/setRedirect', {
        name: to.name,
        params: to.params,
        query: to.query,
      });

      return next({
        name: ENUMS.ROUTES.AUTH.SIGNIN,
        params: { ...to.params },
      });
    }
    return next();
  });

  return router;
}
